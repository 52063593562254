import { setupAPI, ServerEnv, ServiceDomain } from '@swingvy/data-provider';
import { moment } from '@swingvy/date';
import RoutePath from 'js/app/RoutePath';

const getEnvName = Object.values(ServerEnv).filter(
    (k) => window.location.hostname.includes(k as string) && k,
);
console.log('server env', getEnvName);

export const {
    graphql: { gql, client: graphql },
    rest,
} = setupAPI({
    env: getEnvName?.[0] || ServerEnv.prod,
    service: ServiceDomain.secure,
    isDev: process.env.NODE_ENV !== 'production',
    timezone: moment.tz.guess(),
});

const redirectToLoginPage = () => {
    document.location.href = `${RoutePath.LOGOUT}?redirect=${document.location.href}`;
};

rest.helper.setErrorHandlingInterceptor({
    onInvalidSession: async () => {
        if (process.env.NODE_ENV !== 'production') {
            console.log('⛔️ Interceptor::onInvalidSession');
        }
        redirectToLoginPage();
    },
    onTokenExpired: async () => {
        if (process.env.NODE_ENV !== 'production') {
            console.log('⛔️ Interceptor::onTokenExpired');
        }
        await rest.session.refreshToken();
    },
});
